// src/components/MessageTable.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Container, Button, Pagination } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';

const MessageTable = () => {
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(10); // Number of messages per page

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get('https://log.manogar.in/api/logs');
      const sortedMessages = response.data.sort((a, b) => new Date(b.timeCreated) - new Date(a.timeCreated));
      setMessages(sortedMessages);
    } catch (error) {
      console.error("There was an error fetching the messages!", error);
    }
  };

  const deleteMessage = async (id) => {
    try {
      await axios.delete(`https://log.manogar.in/api/logs/${id}`);
      setMessages(messages.filter(msg => msg.id !== id));
      toast.success('Message deleted successfully!');
    } catch (error) {
      console.error("There was an error deleting the message!", error);
      toast.error('Failed to delete message.');
    }
  };
  const deleteAllMessages = async () => {
    try {
      await axios.delete('https://log.manogar.in/api/logs');
      setMessages([]);
      toast.success('All messages deleted successfully!');
    } catch (error) {
      console.error("There was an error deleting all messages!", error);
      toast.error('Failed to delete all messages.');
    }
  };

  // Pagination logic
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container>
      <h2 className="my-4 text-center bg-info p-3">Logged Messages</h2>
      <Button variant="danger" className="mb-3 float-end" onClick={deleteAllMessages}>Delete All</Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Message</th>
            <th>Source</th>
            <th>Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentMessages.map((msg) => (
            <tr key={msg.id}>
              <td>{msg.message}</td>
              <td>{msg.source}</td>
              <td>{msg.timeCreated}</td>
              <td>
                <Button variant="danger" onClick={() => deleteMessage(msg.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {[...Array(Math.ceil(messages.length / messagesPerPage)).keys()].map(number => (
          <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>
      <ToastContainer />
    </Container>
  );
};

export default MessageTable;
