import './App.css';
import MessageTable from './components/MessageTable';

function App() {
  return (
    <div>
        <MessageTable />
    </div>
  );
}

export default App;
